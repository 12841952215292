/**
 * Top10 Blue Color
 *
 * From brighter to draker
 *
 * @returns {Array}
 */

export const blue = {
  100: "#E6F4FD",
  200: "#82C6F3",
  300: "#1789D5",
  400: "#1564BF",
}
