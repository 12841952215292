import { blue, green, grey, magenta, purple, yellow, icons } from "./index"
import { typography } from "./typography"
import { common } from "./common"

export const colors = {
  blue,
  green,
  grey,
  magenta,
  purple,
  yellow,
  typography,
  common,
  icons,
}
