import React from "react"
import styled, { css } from "styled-components"
import Link from "next/link"
import { APP_NAME } from "../../../utils/constants"
import { Logo } from "./Logo"

const StyledIcon = styled(Logo)``

const StyledTitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #2f426c;
  font-weight: 600;
  ${(props) =>
    props.$color &&
    css`
      color: ${props.$color};
    `}
`

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const AppIcon = ({
  className,
  color,
  isTitle,
}: {
  className?: string
  color?: string
  isTitle?: boolean
}) => {
  return (
    <Link
      href="/"
      style={{ textDecoration: "none", color: "unset" }}
      className={className}
      title={"Home"}
    >
      <StyledContainer>
        <StyledIcon color={color} />
        {isTitle && <StyledTitle $color={color}>{APP_NAME}</StyledTitle>}
      </StyledContainer>
    </Link>
  )
}
