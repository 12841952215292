import React from "react"
import styled from "styled-components"
import { AppIcon } from "./AppIcon/AppIcon"

const StyledHeader = styled.header`
  background-color: ${(props) => props.theme.palette.common.white};
  min-height: 70px;
  width: 100%;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.palette.colors.grey["200"]};
`

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
  padding: 0 16px;
  max-width: 680px;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    padding: 0;
  }
`

const StyledAppIcon = styled(AppIcon)`
  margin-right: 8px;
`

export const Header = () => {
  return (
    <StyledHeader>
      <StyledContent>
        <StyledAppIcon isTitle />
      </StyledContent>
    </StyledHeader>
  )
}
