import { colors, common, typography } from "../colors"

const { magenta, purple, grey, yellow, blue } = colors

export const darkPalette = {
  colors,
  common,
  primary: {
    light: magenta[600],
    main: magenta[500],
    dark: magenta[600],
  },
  secondary: {
    light: grey[400],
    main: grey[200],
    dark: grey[100],
  },
  error: {
    light: magenta[600],
    main: magenta[500],
    dark: magenta[600],
  },
  link: {
    color: blue[200],
    hover: blue[300],
  },
  star: {
    selected: yellow[300],
    default: grey[300],
  },
  text: {
    primary: grey[100],
    // This color is going to move to grey palette after the design refactoring
    secondary: grey[500],
    highlighted: magenta[500],
  },
  background: {
    paper: grey[1000],
    section: grey[100],
    black: grey[1000],
  },
  button: {
    disabled: grey[300],
    text: {
      color: blue[300],
      hover: {
        color: blue[400],
      },
    },
    contained: {
      background: purple[700],
      color: grey[300],
      hover: {
        background: purple[800],
      },
    },
    outlined: {
      color: common.white,
      border: common.white,
      background: purple[700],
      hover: {
        background: purple[800],
        color: common.white,
      },
    },
  },
  label: {
    default: {
      color: grey[100],
      hover: grey[200],
    },
    active: {
      color: magenta[200],
      hover: magenta[300],
    },
    svg: {
      hover: {
        stroke: magenta[600],
        fill: magenta[600],
      },
    },
  },
  divider: grey[200],
  input: {
    background: grey[1000],
    color: typography[400],
    border: grey[600],
    focus: {
      border: blue[300],
    },
  },
  outline: {
    color: blue[300],
  },
}
