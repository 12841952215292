import { colors, common } from "./colors"

const { magenta, purple, grey, yellow, blue, typography } = colors

const palette = {
  colors,
  common,
  primary: {
    light: purple[300],
    main: purple[600],
    dark: purple[700],
  },
  secondary: {
    light: purple[300],
    main: purple[600],
    dark: purple[700],
  },
  error: {
    light: magenta[600],
    main: magenta[500],
    dark: magenta[600],
  },
  link: {
    color: blue[300],
    hover: blue[400],
  },
  star: {
    selected: yellow[300],
    default: grey[300],
  },
  text: {
    primary: typography[1000],
    // This color is going to move to grey palette after the design refactoring
    secondary: typography[900],
    highlighted: magenta[500],
  },
  background: {
    paper: common.white,
    section: grey[100],
    black: purple[700],
  },
  button: {
    disabled: grey[300],
    text: {
      color: blue[300],
      hover: {
        color: blue[400],
      },
    },
    contained: {
      background: purple[600],
      color: common.white,
      hover: {
        background: purple[800],
      },
    },
    outlined: {
      color: purple[600],
      border: purple[700],
      hover: {
        background: purple[700],
        color: common.white,
      },
    },
  },
  label: {
    default: {
      color: grey[100],
      hover: grey[200],
    },
    active: {
      color: magenta[200],
      hover: magenta[300],
    },
    svg: {
      hover: {
        stroke: magenta[600],
        fill: magenta[600],
      },
    },
  },
  divider: grey[200],
  input: {
    background: "#F4F6FB",
    color: typography[1000],
    border: grey[200],
    focus: {
      border: blue[300],
    },
  },
  outline: {
    color: blue[300],
  },
}

export default palette
