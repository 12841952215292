import typography from "./typography"
import shape from "./shape"
import palette from "./palette"
import shadows from "./shadows"
import zIndex from "./zIndex"
import createBreakpoints from "./breakpoints"

export const defaultTheme = {
  spacing: 8,
  breakpoints: createBreakpoints(),
  typography,
  shape,
  palette,
  shadows,
  zIndex,
}
