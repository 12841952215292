import Head from "next/head"
import { Header } from "./Header"
import styled, { css } from "styled-components"
import { Footer } from "./Footer"
import { ThemeProvider } from "styled-components"
import { getSizes } from "../../utils/styles"
import { defaultTheme, GlobalStyle } from "../style"
import { AppContextProvider } from "../../contexts"

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  min-height: 100dvh;
`

const StyledContent = styled.main`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 0 16px;
  max-width: 680px;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    padding: 0;
  }
`

export default function AppLayout({ children }) {
  return (
    <AppContextProvider>
      <ThemeProvider theme={defaultTheme}>
        <StyledContainer>
          <GlobalStyle />
          <Header />
          <StyledContent>{children}</StyledContent>
          <Footer />
        </StyledContainer>
      </ThemeProvider>
    </AppContextProvider>
  )
}
