import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider as StyledThemeProvider } from "styled-components"
import { defaultTheme } from "./defaultTheme"
import { GlobalStyle } from "./GlobalStyle"

export const ThemeProvider = (props) => {
  const { children, theme = defaultTheme } = props

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({ spacing: PropTypes.number }),
}
