import React from "react"
import styled from "styled-components"

const StyledPiece = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;
  display: block;
  height: ${(props) => props.$size}px;
  width: ${(props) => props.$size}px;
  border-radius: 50%;
  background-image: conic-gradient(
    transparent 0deg,
    transparent 270deg,
    ${(props) => props.$color || props.theme.palette.colors.purple[700]} 270deg,
    ${(props) => props.$color || props.theme.palette.colors.purple[700]} 360deg,
    transparent 0
  );
`

const StyledCircle = styled.div`
  display: block;
  height: ${(props) => props.$size}px;
  width: ${(props) => props.$size}px;
  border-radius: 50%;
  background-image: conic-gradient(
    ${(props) => props.$color || props.theme.palette.colors.purple[700]} 270deg,
    transparent 0
  );
`

const StyledContainer = styled.div`
  position: relative;
`

const StyledWrapper = styled.div`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Logo = ({
  className,
  color,
  size = 30,
}: {
  className?: string
  color?: string
  size?: number
}) => {
  const circleSize = Math.floor(0.75 * size)
  return (
    <StyledWrapper className={className} $size={size}>
      <StyledContainer>
        <StyledCircle $color={color} $size={circleSize} />
        <StyledPiece $color={color} $size={circleSize} />
      </StyledContainer>
    </StyledWrapper>
  )
}
