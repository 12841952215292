/**
 * Top10 Magenta Color
 *
 * From brighter to draker
 *
 * @returns {Array}
 */

export const magenta = {
  100: "#FFF1F2",
  200: "#FFC9D0",
  300: "#F7ADB6",
  400: "#FF8897",
  500: "#FF4A64",
  600: "#BC284B",
}
