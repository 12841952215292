/**
 * Top10 Green Color
 *
 * From brighter to draker
 *
 * @returns {Array}
 */

export const green = {
  100: "#E1F4F1",
  200: "#09A789",
  300: "#02826A",
  400: "#035D4C",
}
