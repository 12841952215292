import React from "react"
import styled, { useTheme } from "styled-components"
import { AppIcon } from "./AppIcon"
import { Divider } from "../neutral/Divider"
import Link from "next/link"

const StyledContainer = styled.footer`
  display: flex;
  width: 100%;
  border-top: 1px solid;
  border-color: ${(props) => props.theme.palette.colors.grey[200]};
  background-color: ${(props) => props.theme.palette.colors.purple[700]};
  color: ${(props) => props.theme.palette.common.white};
  min-height: 300px;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-right: 16px;
  padding-left: 16px;
  max-width: 680px;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
  }
`

const StyledName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledDivider = styled(Divider)`
  border-color: ${(props) => props.theme.palette.common.white};
  width: 100%;
  margin: 24px 0;

  @media screen and (min-width: 768px) {
  }
`

const StyledLinkedin = styled.div`
  width: 35px;
  height: 35px;
  border: 1px solid white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledDescription = styled.div`
  font-size: 14px;
`

const StyledStrong = styled.strong`
  margin-left: 4px;
`

export const Footer = () => {
  const theme = useTheme()
  return (
    <StyledContainer>
      <StyledContent>
        <StyledLeft>
          <StyledName>
            <AppIcon color={theme.palette.common.white} isTitle />
            <div>{` @${new Date().getFullYear()}`}</div>
          </StyledName>
        </StyledLeft>

        <StyledDivider />
        <StyledDescription>
          I am building great web applications with a focus on the
          <StyledStrong>
            high quality code, reusable components, good infrastructures, responsive
            design, code style guide
          </StyledStrong>
          . I am constantly sharing useful knowledge that can help others. Hire Front
          end developer with more than a decade of experience. When I deliver the
          project your team can easily keep working on it effortlessly due to the
          quality code.
          <br />
          <br />
          As a bonus, all the code comes charged with positive energy.
        </StyledDescription>
        <StyledDivider />
        <Link
          href="https://cal.com/jenny-abovsky"
          style={{
            textDecoration: "none",
            color: "unset",
            fontWeight: 700,
            marginBottom: 16,
          }}
          target="_blank"
          rel="noopener noreferrer"
          title={"Setup a Meeting"}
        >
          Setup a Meeting
        </Link>

        <Link
          href="https://wa.me/+972549256658"
          style={{
            textDecoration: "none",
            color: "unset",
            fontWeight: 700,
            marginBottom: 16,
          }}
          target="_blank"
          rel="noopener noreferrer"
          title={"Contact Us"}
        >
          Contact Us
        </Link>
        <Link
          href="/projects"
          style={{
            textDecoration: "none",
            color: "unset",
            fontWeight: 700,
            marginBottom: 16,
          }}
          title={"Projects"}
        >
          Projects
        </Link>
        <Link
          href="https://www.linkedin.com/in/jenny-abovsky/"
          style={{ textDecoration: "none", color: "unset", marginBottom: 16 }}
          target="_blank"
          rel="noopener noreferrer"
          title={"Linkedin"}
        >
          <StyledLinkedin>
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMuMTUyIDE0LjkwNVY1LjEwMkguMTIydjkuODAzaDMuMDN6bTAtMTMuMjc3QzMuMTM0Ljc1IDIuNTQ5LjA4MiAxLjU5OS4wODJTLjAzLjc1LjAzIDEuNjI4YzAgLjg1OC42MDIgMS41NDUgMS41MzQgMS41NDVoLjAxOGMuOTY4IDAgMS41Ny0uNjg3IDEuNTctMS41NDV6bTQuOTQzIDEzLjI3N1Y5LjMyYzAtLjI5OS4wMjEtLjU5OC4xMS0uODEuMjQtLjU5OC43ODYtMS4yMTcgMS43MDUtMS4yMTcgMS4yMDMgMCAxLjk1Ny42ODMgMS45NTcgMi4wMjd2NS41ODVoMy4wNVY5LjE3MWMwLTMuMDcyLTEuNjQtNC41MDItMy44MjctNC41MDItMS43OTMgMC0yLjU4IDEuMDAzLTMuMDE4IDEuNjg1bC4wMjItMS4zMUg1LjA1Yy4wNDQuOTM4IDAgOS44NjEgMCA5Ljg2MWgzLjA0NnoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg=="
              alt="UI component lib on LinkedIn"
              title={"UI component lib on LinkedIn"}
            />
          </StyledLinkedin>
        </Link>
      </StyledContent>
    </StyledContainer>
  )
}
