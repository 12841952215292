export const APP_NAME = "UI Component Lib"

export const revalidateNext = 1000000

export const APP_TITLE =
  "Web projects,UI bugs solutions,UI best practices,Hire Front End"
export const APP_DESCRIPTION =
  "Web projects, UI bugs solutions, UI best practices, Hire freelance ui developer with more than a decade of experience."
export const APP_URL = "https://uicomponentlib.com"
export const APP_KEYWORDS =
  "Web projects,UI bugs solutions,freelance ui developer,ui best practices"
