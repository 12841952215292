import { css } from "styled-components"

export const scrollStyle = css`
  ::-webkit-scrollbar {
    width: 8px;
    height: 100%;
  }

  ::-webkit-scrollbar-button {
    height: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d8dffb;
    border-right: 3px transparent solid;
    border-left: 3px transparent solid;
    border-top: 16px transparent solid;
    border-bottom: 16px transparent solid;
    background-clip: padding-box;
    height: 100px;
  }
`
