const typography = {
  fontFamily: "Noto Sans, sans-serif",
  fontSize: 16,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightLarge: 600,
  fontWeightBold: 700,
  h1: {
    fontSize: 35,
    fontWeight: 700,
  },
  h2: {
    fontSize: 30,
    fontWeight: 700,
  },
  h3: {
    fontSize: 22,
    fontWeight: 700,
  },
  h4: {
    fontSize: 18,
    fontWeight: 700,
  },
  p: {
    fontSize: 16,
    fontWeight: 400,
  },
  p1: {
    fontSize: 14,
    fontWeight: 400,
  },
  p2: {
    fontSize: 12,
    fontWeight: 400,
  },
  p3: {
    fontSize: 10,
    fontWeight: 400,
  },
}

export default typography
