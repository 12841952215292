/**
 * Top10 Purple Color
 *
 * From brighter to draker
 *
 * @returns {Array}
 */

export const purple = {
  100: "#EDECF3",
  200: "#A3A2C3",
  300: "#8E8BB2",
  400: "#7A78A8",
  500: "#514F8E",
  600: "#29256B",
  700: "#010233",
  800: "#00011a",
}
