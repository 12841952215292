function createBreakpoints(breakpoints = {}) {
  const values = {
    375: 375,
    768: 768,
    1000: 1000,
    1200: 1200,
    ...breakpoints,
  }

  const getValue = (val) => {
    return values[val] || val
  }

  return {
    values,
    above(key) {
      return `@media screen and (min-width: ${getValue(key)}px)`
    },
    between(start, end) {
      return `@media screen and (min-width: ${getValue(start)}px) and (max-width: ${
        getValue(end) - 1
      }px)`
    },
  }
}

export default createBreakpoints
