/**
 * Top10 Grey Color
 *
 * From brighter to draker
 *
 * @returns {Array}
 */

export const grey = {
  100: "#F5F5F5",
  200: "#E2E2E2",
  300: "#D5D5D5",
  400: "#BDBDBD",
  500: "#AAAAAA",
  600: "#999999",
  700: "#797979",
  800: "#484848",
  900: "#35363A",
  1000: "#2D2D2D",
  1100: "#171717",
  1200: "#323232",
}
