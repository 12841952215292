import React from "react"
import get from "lodash/get"

import styled from "../style/styled"
import palette from "../style/palette"

const StyledDivider = styled.div`
  border-bottom: 1px solid;
  border-color: ${(props) => get(props, "theme.palette.divider", palette.divider)};
`

export const Divider = React.forwardRef(({ ...rest }, ref) => {
  return <StyledDivider ref={ref} {...rest} />
})
Divider.displayName = "Divider"
