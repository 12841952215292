import AppLayout from "../components/AppLayout/AppLayout"
import Head from "next/head"

export default function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <AppLayout>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 user-scalable=no"
        />
      </Head>
      {getLayout(<Component {...pageProps} />)}
    </AppLayout>
  )
}
