import { icons } from "./icons"

export * from "./blue"
export * from "./green"
export * from "./grey"
export * from "./magenta"
export * from "./purple"
export * from "./yellow"
export * from "./colors"
export * from "./common"
export * from "./typography"
export * from "./icons"
