/**
 * Top10 Yellow Color
 *
 * From brighter to draker
 *
 * @returns {Array}
 */

export const yellow = {
  100: "#FFF6E0",
  200: "#FFD266",
  300: "#FFB401",
  400: "#E59B1B",
}
