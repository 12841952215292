import { createContext, useCallback, useContext, useState } from "react"

const AppContext = createContext(null)

export function useAppContext() {
  return useContext(AppContext)
}

export function AppContextProvider({ children }) {
  const [modal, setModal] = useState(null)
  const [posts, setPosts] = useState(null)
  const [tags, setTags] = useState(null)
  const [postsByTag, setPostsByTag] = useState(null)

  const initFunc = useCallback(({ posts, tags }) => {
    setPosts(posts)
    setTags(tags)
    const res = posts.reduce((acc, post) => {
      const tags = post.tags
      if (tags) {
        tags.forEach((item) => {
          if (!acc[item]) {
            acc[item] = []
          }
          acc[item].push(post)
        })
      }
      return acc
    }, {})

    setPostsByTag(res)
  }, [])

  return (
    <AppContext.Provider
      value={{
        posts,
        tags,
        postsByTag,
        initFunc,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
