export const typography = {
  100: "#FBFBFC",
  200: "#F5F6F8",
  300: "#ECEEF2",
  400: "#E2E5EA",
  500: "#D5D9E2",
  600: "#C5CAD6",
  700: "#ACB3C4",
  800: "#8D97AE",
  900: "#637191",
  1000: "#2F426C",
  1100: "#233251",
  1200: "#1E2A44",
  1300: "#172034",
}
